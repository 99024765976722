import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pricePretax', 'priceIncludingTax', 'vat']

  connect() {
    if (this.hasPricePretaxTarget) {
      this.lastUpdated = this.pricePretaxTarget
    }
    this.compute()
  }

  compute() {
    const pricePretax = this.#parseValue(this.pricePretaxTarget.value)
    const priceIncludingTax = this.#parseValue(this.priceIncludingTaxTarget.value)
    const vat = this.#parseValue(this.vatTarget.value)

    if (!priceIncludingTax && !pricePretax) return

    if (document.activeElement === this.pricePretaxTarget) {
      this.#updatePriceIncludingTax(pricePretax, vat)
      this.lastUpdated = this.pricePretaxTarget
    } else if (document.activeElement === this.priceIncludingTaxTarget) {
      this.#updatePricePretax(priceIncludingTax, vat)
      this.lastUpdated = this.priceIncludingTaxTarget
    } else {
      if (this.lastUpdated === this.pricePretaxTarget) {
        this.#updatePriceIncludingTax(pricePretax, vat)
      } else {
        this.#updatePricePretax(priceIncludingTax, vat)
      }
    }
  }

  #parseValue(value) {
    return parseFloat(value.replace(',', '.')) || 0
  }

  #updatePriceIncludingTax(pricePretax, vat) {
    this.priceIncludingTaxTarget.value = this.#formatValue(pricePretax * (1 + vat))
  }

  #updatePricePretax(priceIncludingTax, vat) {
    this.pricePretaxTarget.value = this.#formatValue(priceIncludingTax / (1 + vat))
  }

  #formatValue(value) {
    return value.toFixed(2).replace('.', ',')
  }
}
